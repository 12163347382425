import { CSRFToken } from '../contexts/loginContext'

export interface Caps {
  [key: string]: number
}

export const enum Throughputs {
  LOW = 'LOW',
  READONLY = 'READONLY',
  HIGH = 'HIGH',
}

export interface Tenant {
  identity: string
  anchor_interval: number
  caps: Caps
  throughput: Throughputs
  verified_domain: string
  expired: boolean
}

export interface TenantUpdate {
  anchor_interval?: number
  caps?: Caps
  throughput?: Throughputs
  verified_domain?: string
  expired?: boolean
}

interface DataTrailsError {
  code: number
  message: string
  details: any
}

export const loadTenant = async (tenantID: string): Promise<Tenant> => {
  const response = await fetch('/session/archivist/internal/v1/tenanciesmgmt/' + tenantID)
  if (!response.ok) {
    const body = (await response.json()) as DataTrailsError
    throw new Error(body.message)
  }
  const tenant = (await response.json()) as Tenant
  return tenant
}

export const updateTenant = async (tenantID: string, tenantUpdate: TenantUpdate): Promise<Tenant> => {
  const response = await fetch('/session/archivist/internal/v1/tenanciesmgmt/' + tenantID.substring(7), {
    method: 'PATCH',
    body: JSON.stringify(tenantUpdate),
    headers: {
      'x-csrf-token': CSRFToken,
    },
  })
  if (!response.ok) {
    const body = (await response.json()) as DataTrailsError
    throw new Error(body.message)
  }
  const tenant = (await response.json()) as Tenant
  return tenant
}
