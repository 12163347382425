import React from 'react'
import { Link, useRouteError, isRouteErrorResponse } from 'react-router-dom'

const ErrorPage = () => {
  const error = useRouteError()
  let errorMessage: string

  if (isRouteErrorResponse(error)) {
    // error is type `ErrorResponse`
    errorMessage = error.error?.message || error.statusText
  } else if (error instanceof Error) {
    errorMessage = error.message
    console.log('Error', error.message)
  } else if (typeof error === 'string') {
    console.log('string')
    errorMessage = error
  } else {
    console.error(error)
    errorMessage = 'Unknown error'
  }

  return (
    <div className="loggedOut">
      <p>{errorMessage}</p>
      <p>Please reload the page or navigate back to</p>
      <Link to="/" reloadDocument={true}>
        home page
      </Link>
    </div>
  )
}

export default ErrorPage
